import * as turf from "@turf/turf";

type Coordinate = [number, number, number?, number?]; // [x, y, z?, m?]

export interface IntersectionResult {
  intersections: {
    type: "overlap" | "collision";
    coordinates: number[][]; // The 2D coordinates of the intersection polygon
  }[];
}

/**
 * Finds the intersections between two polygons and classifies them as 'overlap' or 'collision'.
 *
 * @param polygon1 - The first polygon as an array of coordinates [x, y, z, m].
 * @param polygon2 - The second polygon as an array of coordinates [x, y, z, m].
 * @param buffer - The vertical buffer distance to add to the height ranges.
 * @returns An object containing the intersections categorized as 'overlap' or 'collision'.
 */
export function findPolygonIntersections(
  polygon1: Coordinate[],
  polygon2: Coordinate[],
  buffer: number = 0 // Vertical buffer distance
): IntersectionResult {
  // Convert polygons to Turf.js GeoJSON Polygons (ignore Z and M for 2D checks)
  const turfPolygon1 = turf.polygon([polygon1.map(([x, y]) => [x, y])]);
  const turfPolygon2 = turf.polygon([polygon2.map(([x, y]) => [x, y])]);

  // Use Turf.js to find all intersections (can return multiple features)
  const intersection = turf.intersect(
    turf.featureCollection([turfPolygon1, turfPolygon2])
  );

  if (!intersection || !intersection.geometry) {
    // No intersection in 2D
    return { intersections: [] };
  }

  // Extract the intersection geometries (handles MultiPolygon or Polygon)
  const geometries: number[][][] =
    intersection.geometry.type === "MultiPolygon"
      ? intersection.geometry.coordinates
      : [intersection.geometry.coordinates];

  // Iterate through each intersecting geometry and determine type
  const intersections = geometries.map((geometry) => {
    const intersectionCoordinates = geometry[0]; // Get the first ring of the polygon

    // Get height ranges for this intersecting area
    const [z1Min, z1Max] = getHeightRangeForIntersection(
      polygon1,
      intersectionCoordinates
    );
    const [z2Min, z2Max] = getHeightRangeForIntersection(
      polygon2,
      intersectionCoordinates
    );

    // Adjust height ranges with buffer
    const z1BufferedMin = z1Min - buffer;
    const z1BufferedMax = z1Max + buffer;
    const z2BufferedMin = z2Min - buffer;
    const z2BufferedMax = z2Max + buffer;

    // Check if height ranges collide or overlap
    const heightCollides =
      z1BufferedMin < z2BufferedMax && z2BufferedMin < z1BufferedMax;
    const type = heightCollides ? "collision" : "overlap";

    return {
      type,
      coordinates: intersectionCoordinates,
    };
  });

  return { intersections };
}

/**
 * Gets the height range (Z and M) of a polygon.
 *
 * @param polygon - The polygon as an array of coordinates [x, y, z, m].
 * @returns A tuple [zMin, zMax] representing the height range of the polygon.
 */
function getHeightRange(polygon: Coordinate[]): [number, number] {
  let zMin = Infinity;
  let zMax = -Infinity;

  for (const [, , z = 0, m = 0] of polygon) {
    zMin = Math.min(zMin, z);
    zMax = Math.max(zMax, m);
  }

  return [zMin, zMax];
}

function getHeightRangeForIntersection(
  polygon: Coordinate[],
  intersection: number[][]
): [number, number] {
  let zMin = Infinity;
  let zMax = -Infinity;

  // Iterate through the intersection points
  for (const [ix, iy] of intersection) {
    // Find the corresponding point in the original polygon
    const matchingPoint = polygon.find(
      ([px, py]) => Math.abs(px - ix) < 1e-6 && Math.abs(py - iy) < 1e-6
    );
    if (matchingPoint) {
      const [, , z = 0, m = 0] = matchingPoint;
      zMin = Math.min(zMin, z);
      zMax = Math.max(zMax, m);
    }
  }

  return [zMin, zMax];
}

/**
 * Interpolates a polygon by adding intermediate points between existing vertices.
 *
 * @param polygon - The polygon as an array of coordinates [x, y, z, m].
 * @param steps - The number of interpolated points to add between each pair of vertices.
 * @returns The interpolated polygon as an array of coordinates [x, y, z, m].
 */
export function interpolatePolygon(
  polygon: Coordinate[],
  steps: number
): Coordinate[] {
  if (steps < 1 || polygon.length < 2) {
    return polygon; // No interpolation needed
  }

  const interpolated: Coordinate[] = [];

  for (let i = 0; i < polygon.length - 1; i++) {
    const start = polygon[i];
    const end = polygon[i + 1];

    // Add the start point
    interpolated.push(start);

    // Interpolate points between start and end
    for (let step = 1; step <= steps; step++) {
      const t = step / (steps + 1); // Interpolation factor

      const interpolatedPoint: Coordinate = [
        interpolateValue(start[0], end[0], t), // Interpolate X
        interpolateValue(start[1], end[1], t), // Interpolate Y
        interpolateValue(start[2] ?? 0, end[2] ?? 0, t), // Interpolate Z (default to 0 if undefined)
        interpolateValue(start[3] ?? 0, end[3] ?? 0, t), // Interpolate M (default to 0 if undefined)
      ];

      interpolated.push(interpolatedPoint);
    }
  }

  // Add the last point to close the polygon if needed
  interpolated.push(polygon[polygon.length - 1]);

  return interpolated;
}

/**
 * Linearly interpolates between two values.
 *
 * @param start - The start value.
 * @param end - The end value.
 * @param t - The interpolation factor (0 <= t <= 1).
 * @returns The interpolated value.
 */
function interpolateValue(start: number, end: number, t: number): number {
  return start + t * (end - start);
}
