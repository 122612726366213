import * as turf from "@turf/turf";
import * as geometric from "geometric";
import { range } from "lodash";
import * as React from "react";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { Bounds, EntityPolygon, Hangar, Ramp } from "../../../types";
import {
  findPolygonIntersections,
  interpolatePolygon,
  IntersectionResult,
} from "../../../utils/geometry";

export const doBoundsIntersect = (
  boundsA: Bounds,
  boundsB: Bounds
): boolean => {
  const [topLeftA, bottomRightA] = boundsA;
  const [topLeftB, bottomRightB] = boundsB;
  // check if boundsA is to the right of boundsB
  if (topLeftA[0] > bottomRightB[0]) {
    return false;
  }

  // check if boundsA is to the left of boundsB
  if (bottomRightA[0] < topLeftB[0]) {
    return false;
  }

  // check if boundsA is above boundsB
  if (topLeftA[1] > bottomRightB[1]) {
    return false;
  }
  // check if boundsA is below boundsB
  if (bottomRightA[1] < topLeftB[1]) {
    return false;
  }
  return true;
};

const cache = {};
export const doBoundsIntersectWithCache = (
  boundsA: Bounds,
  boundsB: Bounds
): boolean => {
  const keyA = JSON.stringify(boundsA);
  const keyB = JSON.stringify(boundsB);
  const key = [keyA, keyB].sort().join("|");
  if (cache[key]) {
    return cache[key];
  }
  const result = doBoundsIntersect(boundsA, boundsB);
  cache[key] = result;
  return result;
};

export const getIntersection = (
  a: EntityPolygon,
  b: EntityPolygon,
  buffer: number = 0
): IntersectionResult => {
  if (!a || !b) {
    return {
      intersections: [],
    };
  }
  // first, check to see if the bounds overlap at all
  if (doBoundsIntersectWithCache(a.bounds, b.bounds) === false) {
    return {
      intersections: [],
    };
  }

  // if the bounds overlap, then we need to do a more expensive check
  const turfPolygon1 = turf.polygon([a.polygon]);
  const turfPolygon2 = turf.polygon([b.polygon]);
  const intersection = turf.intersect(
    turf.featureCollection([turfPolygon1, turfPolygon2])
  );
  if (!intersection || !intersection.geometry) {
    return {
      intersections: [],
    };
  }

  const results = findPolygonIntersections(a.polygon, b.polygon, buffer);
  return results;
};

// we need to get a full polygon for the collision overlay so it looks filled in and matches
// the shape of the airplane
export const interpolatePolygonOld = (polygon: number[][]) => {
  const interpolater = geometric.polygonInterpolate(polygon);
  const interpolatedPolygon = range(0, 1, 0.001).map((t) => interpolater(t));
  return interpolatedPolygon;
};

const intersectionsCache = new Map();

const getMemoizedIntersection = (
  polygonA: EntityPolygon,
  polygonB: EntityPolygon,
  buffer: number = 0
) => {
  // comparing A to B and B to A are the same thing
  const key = [polygonA.hash, polygonB.hash].sort().join("|");
  if (intersectionsCache.has(key)) {
    return intersectionsCache.get(key);
  } else {
    const intersection = getIntersection(polygonA, polygonB, buffer);
    intersectionsCache.set(key, intersection);
    return intersection;
  }
};

type Props = {
  location: Hangar | Ramp;
  polygons: EntityPolygon[];
};

export const CollisionOverlay: React.FC<Props> = ({ location, polygons }) => {
  const { activeFBO } = useActiveFBOs();
  const CollisionComponents = React.useMemo(() => {
    const collisions = [];
    const overlaps = [];
    // compare each polygon to each other polygon but only one time in either direction
    for (let i = 0; i < polygons.length; i++) {
      for (let j = i + 1; j < polygons.length; j++) {
        const intersection = getMemoizedIntersection(
          polygons[i],
          polygons[j],
          activeFBO?.sop_vertical_spacing ?? 0
        );
        for (const region of intersection.intersections) {
          if (region.type === "collision") {
            collisions.push(interpolatePolygon(region.coordinates, 2));
          }
          if (region.type === "overlap") {
            overlaps.push(interpolatePolygon(region.coordinates, 2));
          }
        }
      }
    }
    // return an SVG polygon for each collision
    return [
      ...collisions.map((collisionPolygon, idx) => {
        const collisionPoints =
          collisionPolygon.length === 1
            ? collisionPolygon[0]
            : collisionPolygon;
        // convert the collision polygon to a string of points for the SVG
        const points = collisionPoints
          .map((point) => `${point[0]}, ${location.depth - point[1]}`)
          .join(" ");
        return (
          <polygon
            key={`collision-polygon-${idx}`}
            points={points}
            fill="#FF0000"
            opacity={0.9}
          />
        );
      }),
      ...overlaps.map((overlapPolygon, idx) => {
        const overlapPoints =
          overlapPolygon.length === 1 ? overlapPolygon[0] : overlapPolygon;
        // convert the collision polygon to a string of points for the SVG
        const points = overlapPoints
          .map((point) => `${point[0]}, ${location.depth - point[1]}`)
          .join(" ");

        return (
          <polygon
            key={`overlap-polygon-${idx}`}
            points={points}
            fill="#FFC300"
            opacity={0.5}
          />
        );
      }),
    ];
  }, [polygons]);

  // return a big SVG that we can overlay on top of the hangar/ramp
  return (
    <svg
      data-id={`collision-overlay-${location.name}`}
      data-name="collisions"
      key={`collision`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${location.width} ${location.depth}`}
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: "5000",
        // So that clicking doesn't interfere with dragging
        pointerEvents: "none",
      }}
    >
      {CollisionComponents}
    </svg>
  );
};
